// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-align: left;
}

.search-tips {
  margin: 10px 25px;
  color: #707070;
}

.search-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
}
.search-input-container input {
  flex: 0 0 90%;
  font-size: 14px;
  height: 48px;
}
.search-input-container .search-btn {
  display: flex;
  justify-items: center;
  align-items: center;
  background: #0066b2 0% 0% no-repeat padding-box;
  padding-left: 30px;
  width: 10%;
  height: 48px;
  color: white;
  font-family: "GothamBold", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  float: left;
}
.search-input-container .search-btn:hover {
  background-color: #0b2d71;
}
.search-input-container .clear-button {
  height: 36px;
  width: 20px;
  margin-left: -18%;
}
.search-input-container .clear-button .button {
  border: none;
}

.add-search-results-to-workspace-button {
  background-color: #0066b2;
  color: #ffffff;
  border: none;
  padding: 0 20px;
  margin-right: auto;
  font-size: 14px;
  font-family: "GothamBold", Arial, sans-serif;
}

.add-search-results-to-workspace-button:hover {
  cursor: pointer;
  background-color: #0b2d71;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.container-vertical {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

.search-result-pane {
  flex: 3 100%;
  flex-basis: 100%;
  max-width: 75%;
}

.filters-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-top: 10px;
  overflow-y: auto;
}

.filter-group * {
  justify-content: space-between;
}

.filter-group-item {
  padding-left: 24px;
  line-height: 40px;
  border: 1px solid rgb(237, 237, 238);
  border-radius: 3px;
}
.filter-group-item span {
  color: #0066b2;
  font-size: 12px;
  font-weight: bold;
}

.filter-group-heading {
  align-items: center;
  line-height: 40px;
  border: none;
  border-radius: 3px;
  font-weight: bolder;
  color: black;
  max-height: 40px;
}
.filter-group-heading p {
  margin-left: 10px;
}

.items-per-page {
  margin: 2%;
}

.mat-mdc-paginator-container > * {
  font-family: "Gotham", "Arial", sans-serif;
}

.mat-mdc-select-min-line {
  font-family: "Gotham", "Arial", sans-serif;
}

.reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset .text-button {
  font-size: 14px;
  padding-right: 4%;
}
.reset .small-filter-heading {
  font-size: 18px;
  margin-top: 10%;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bolder;
}

.reset-lower {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.reset-lower .text-button {
  font-size: 14px;
  padding-right: 4%;
}

.type-filter-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.type-filter-container .type-sub-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.type-filter-container input {
  width: 15px;
  height: 15px;
}
.type-filter-container .checkbox-type-label {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bolder;
}

.export-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 25px;
  margin-left: 25px;
}

.progress-bar-container {
  min-height: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 25px;
  margin-left: 25px;
}
.progress-bar-container .searching-progress-bar {
  height: 5px;
}

.spinner-message {
  display: flex;
  font-style: "Gotham", Arial, sans-serif;
  align-items: center;
}
.spinner-message .mat-mdc-progress-spinner {
  margin-right: 10px;
  margin-left: 10px;
}

.welding-procedure-preview-pane {
  top: 100px;
}

.horizontal-container-for-results-and-preview {
  display: flex;
  align-items: center;
  flex-grow: 3;
}

.filter-selection-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  padding: 20px 25px;
}
.filter-selection-container .filter-selection-item {
  flex: 0 0 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1%;
}
.filter-selection-container .chips-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.filter-selection-container .chips-container mat-chip-option {
  border-radius: 5px;
  background-color: #0066b2;
}
.filter-selection-container .chips-container mat-chip-option span {
  color: white;
}
.filter-selection-container .chips-container button {
  color: white;
}

.search-tips {
  width: 94%;
}

.temp-unit-container {
  margin: auto;
  display: flex;
  flex-direction: row;
}
.temp-unit-container .checkbox-container {
  margin-right: 25px;
}

.temp-unit-container {
  margin: auto;
  display: flex;
  flex-direction: row;
}
.temp-unit-container .checkbox-container {
  margin-right: 25px;
}

.piping-panel {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  margin: 32px 25px;
  padding: 12px 0;
}
.piping-panel .piping-title {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  width: 95%;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #0066b2;
}
.piping-panel .piping-subtitle {
  display: flex;
  width: 95%;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  color: #0066b2;
  margin-left: 2%;
  margin-right: 2%;
}
.piping-panel .prop-label {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #8c8f93;
  opacity: 1;
  margin: 0;
  padding: 0;
}
.piping-panel .prop-value {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  color: #000000;
  margin: 0;
  padding: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
