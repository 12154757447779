// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-align: left;
}

.main-container {
  padding: 3%;
}

.header-result-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2%;
}

.selected-items-counter {
  min-width: 120px;
  font-weight: bolder;
  text-align: right;
}

.search-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
  flex-grow: 2;
}
.search-input-container input {
  flex: 0 0 90%;
  font-size: 14px;
  height: 48px;
}
.search-input-container .search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0066b2 0% 0% no-repeat padding-box;
  width: 10%;
  height: 48px;
  color: white;
  font-family: "GothamBold", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
}
.search-input-container .search-btn:hover {
  background-color: #0b2d71;
}
.search-input-container .clear-button {
  height: 36px;
  width: 20px;
  margin-left: -18%;
}
.search-input-container .clear-button .button {
  border: none;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.container-vertical, .revision-description, .type-filter-container, .filters-container {
  display: flex;
  flex-direction: column;
}

.search-result-pane {
  flex: 3 100%;
  flex-basis: 100%;
  max-width: 75%;
}

.filters-container {
  width: 25%;
  margin-top: 10px;
  overflow-y: auto;
}

.filter-group * {
  justify-content: space-between;
}

.reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset .text-button {
  font-size: 14px;
  padding-right: 4%;
}
.reset .small-filter-heading {
  font-size: 18px;
  margin-top: 10%;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bolder;
}

.type-filter-container {
  margin-bottom: 10px;
}
.type-filter-container .type-sub-container {
  display: flex;
  align-items: center;
}
.type-filter-container input {
  width: 15px;
  height: 15px;
}

.progress-bar-container {
  min-height: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 25px;
  margin-left: 25px;
}
.progress-bar-container .searching-progress-bar {
  height: 5px;
}

.spinner-message {
  display: flex;
  font-style: "Gotham", Arial, sans-serif;
  align-items: center;
}
.spinner-message .mat-mdc-progress-spinner {
  margin-right: 10px;
  margin-left: 10px;
}

.add-details h3 {
  margin-top: 3%;
  font-weight: bolder;
  text-align: left;
}

.revision-description {
  margin: 0 5%;
  flex-direction: column;
  flex-grow: 2;
}
.revision-description span {
  font-weight: bolder;
}
.revision-description textarea {
  height: 100px;
}

.table-column-hidden-width {
  max-width: 0px;
}

.table-header-content {
  font-weight: bolder;
  font-size: medium;
  color: #000000;
  padding-left: 2%;
}

.table-content {
  padding-left: 2%;
  border-top: 0px;
}

.table-content-actions {
  display: flex;
  max-width: 8%;
  padding-left: 5px;
}
.table-content-actions checkbox {
  color: #0066b2;
}

.paginator {
  max-height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.navigation-buttons {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  gap: 3%;
  justify-content: flex-end;
}
.navigation-buttons button {
  margin: 10px 15px;
}

.add-members {
  margin: 3% 0px;
  display: flex;
  justify-content: flex-start;
}

.creator {
  font-weight: bold;
  color: #707070;
}
.creator .username {
  margin-left: 10%;
  font-weight: normal;
  color: black;
}

.selected-users {
  margin-left: 2%;
}

.owners {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.owners .title {
  margin: 2% 0;
  font-weight: bold;
  color: #707070;
}
.owners .placeholder {
  font-weight: bold;
  color: #8c8f93;
}
.owners .search-box {
  min-width: 250px;
}

.mat-sort-header-arrow {
  color: black;
}

.mat-mdc-row:nth-child(even) .mat-mdc-cell {
  background-color: #e3e3e3;
}

.mat-mdc-row:nth-child(odd) .mat-mdc-cell {
  background-color: white;
}

.mat-mdc-paginator-container > * {
  font-family: "Gotham", "Arial", sans-serif;
}

.mat-mdc-select-min-line {
  font-family: "Gotham", "Arial", sans-serif;
}

.delete-color {
  color: #e21836;
}

.asterisk {
  color: #E21836;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
