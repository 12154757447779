import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonNoteViewModel, ComponentsInPipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-pipe-component-details',
  templateUrl: './pipe-component-details.component.html',
  styleUrls: ['./pipe-component-details.component.scss']
})
export class PipeComponentDetailsComponent implements OnChanges, OnInit{
  @Input()
  groupName: string;
  @Input()
  componentsInPipeClass: ComponentsInPipeClassViewModel[];

  notesMutualForGroup: CommonNoteViewModel[];
  
  expanded = true;

  ngOnInit(): void {
    this.notesMutualForGroup = this.getNotesMutualForGroup(this.componentsInPipeClass);
  }

  ngOnChanges(): void {
    this.sortComponents();
  }
  
  sortComponents() {
    this.componentsInPipeClass.sort((a, b) => {
        if (a.component.type < b.component.type) {
            return -1;
        }

        if (a.component.type > b.component.type) {
            return 1;
        }
 
        return 0;
    });
  }

  getJoinedCommonNoteCodes(componentInPipeClass: ComponentsInPipeClassViewModel): string {
    if(componentInPipeClass.commonNotes && componentInPipeClass.commonNotes.length > 0) {
          return componentInPipeClass.commonNotes.map(commonNote => commonNote.codeId).join(", ");
    }
    return "";
  }

  getNotesMutualForGroup(components: ComponentsInPipeClassViewModel[]): CommonNoteViewModel[] {
    const counterMap = new Map<string, { note: CommonNoteViewModel,
      counter: number}>();
    components.forEach(component => component.commonNotes
                .forEach(note => {
                  const noteAndCounter = counterMap.get(note.codeId);
                  let counter = noteAndCounter?.counter ?? 0
                  counterMap.set(note.codeId, {note: note, counter: ++counter});
                }));

    let mutualNotes = [];
    for(let [key, value] of counterMap){
      if(value.counter == components.length) {
        mutualNotes.push(value.note);
      }
    };

    return mutualNotes;
  }

  excludeMutualNotes(notes: Array<CommonNoteViewModel>): Array<CommonNoteViewModel> {
    return notes.filter(note => this.notesMutualForGroup.filter(nt => nt.codeId === note.codeId).length == 0);
  }
}
