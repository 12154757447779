// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr td {
  background-color: white;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
}

table {
  border: 1px solid silver;
}

table th {
  border: 1px solid silver;
  background-color: #f7f7f7;
}

.mat-icon {
  float: right;
}

.componentType {
  padding-left: 35px;
  font-weight: bold;
}

.ellipses {
  border-bottom: 1px solid silver;
}

.valve-tag {
  color: #0066B2;
  cursor: pointer;
}

table tr th, table tr td {
  padding: 10px 14px;
}

.valve-row-header {
  display: flex;
  max-height: 1%;
}

#row-header-open {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid silver;
  border-left: none;
}

#row-header-closed {
  border: none;
}

.revision-col {
  white-space: nowrap;
}

.sch1rat {
  white-space: nowrap;
}

.note-container {
  display: flex;
  flex-direction: row;
  color: #0066b2;
  border-right-width: 0px;
  border-left-width: 0px;
  background-color: white;
}
.note-container .note-element {
  margin-right: 3px;
}
.note-container .note-element:hover {
  margin-right: 3px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bolder;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
