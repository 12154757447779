import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonNoteViewModel, ComponentsInPipeClassViewModel, PipeClassViewModel, ValveViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-valve-tab',
  styleUrls: ['valve-tab.component.scss'],
  templateUrl: 'valve-tab.component.html',
})
export class ValveTabComponent implements OnInit, OnChanges {
  @Input() pipeClassViewModelValves: PipeClassViewModel['valves'];

  valvesClicked = true;
  valvesPresent = false;

  notesMutualForGroup: CommonNoteViewModel[];

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.sortValves();
  }

  ngOnInit() {
    this.checkForValves();
    this.notesMutualForGroup = this.getNotesMutualForGroup(this.pipeClassViewModelValves);
  }

  checkForValves() {
    this.pipeClassViewModelValves.forEach(() => {
      if (!this.valvesPresent) {
        this.valvesPresent = true;
      }
    });
  }

  toggleValvesClicked() {
    this.valvesClicked = !this.valvesClicked;
  }

  sortValves() {
    this.pipeClassViewModelValves.sort(function (a, b) {
      if (a.componentType.name < b.componentType.name) {
        return -1;
      }
      if (a.componentType.name > b.componentType.name) {
        return 1;
      }
      return 0;
    });
  }

  openValveSummaryPage(valveId: string) {
    this.router.navigate(['/valveSummary', valveId]);
  }

  replaceUnderscoresWithSpaces(inputStr: string): string {
    const strArr = inputStr.split('_');

    if(strArr.length > 1) {
      strArr.shift()
      return strArr.join(" ")
    } else {
      return inputStr;
    }
  }

  extractSubstringBeforeUnderscore(str: string) {
    return str.split('_')[0];
  }

  getJoinedCommonNoteCodes(componentInPipeClass: ComponentsInPipeClassViewModel): string {
    if(componentInPipeClass.commonNotes && componentInPipeClass.commonNotes.length > 0) {
          return componentInPipeClass.commonNotes.map(commonNote => commonNote.codeId).join(", ");
    }
    return "";
  }

  getNotesMutualForGroup(valves: ValveViewModel[]): CommonNoteViewModel[] {
    const counterMap = new Map<string, { note: CommonNoteViewModel,
      counter: number}>();
    valves.forEach(valve => valve.commonNotes
                .forEach(note => {
                  const noteAndCounter = counterMap.get(note.codeId);
                  let counter = noteAndCounter?.counter ?? 0
                  counterMap.set(note.codeId, {note: note, counter: ++counter});
                }));

    let mutualNotes = [];
    for(let [key, value] of counterMap){
      if(value.counter == valves.length) {
        mutualNotes.push(key);
      }
    };

    return mutualNotes;
  }

  excludeMutualNotes(notes: Array<CommonNoteViewModel>): Array<CommonNoteViewModel> {
    return notes.filter(note => this.notesMutualForGroup.filter(nt => nt.codeId === note.codeId).length == 0);
  }
}
